import {gql} from "@apollo/client";

const USER = `
  id
  attributes {
    partyMembers {
      data {
        id
      }
    }
  }
`


export const GET_USER = gql`
query getUser($id:ID!) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        profileComments {
          data {
            id
            attributes {
              body
              createdAt
              user {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const UPDATE_USER = gql`
mutation updateUser ($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
    }
  }
}
`;

export const GET_ME = gql`
query getUser($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
      nickname
      gender
      phone
      deadlineNotificationAllow
      enterNotificationAllow
      notificationAllow
      hostCheck
      introduce
      introducePrivate
      rewardGauge
      blackList
      inviteKey
      inviteMax
      inviteSends {
        data {
          id
        }
      }
      
      tickets {
        data {
          id
          attributes {
            expiresAt
            member {
              data {
                id
              }
            }
          }
        }
      }
      card {
        data {
          id
          attributes {
            name
          }
        }
      }
      coupons {
        data {
          id
          attributes {
            name
            description
            used
            couponType
            couponValue
            expiresAt
          }
        }
      }
      profileImage {
        data {
          id
          attributes {
            url
          }
        }
      }
      profileImagePrivate {
        data {
          id
          attributes {
            url
          }
        }
      }
      subscriptions {
        data {
          id
          attributes {
            startDate
            endDate
            autoRenew
            monthlyCharge
          }
        }
      }
      partyCount
      autoEnter
      bookmarkSends {
        data {
          id
          attributes {
            receiver {
              data {
                id
              }
            }
          }
        }
      }
      partyMembers {
        data {
          id
          attributes {
            review
            party {
              data {
                id
                attributes {
                  building
                  meetedAt
                  address
                  addressDetail
                  restaurantName
                  partyType
                  memberDetail
                  members {
                    data {
                      id
                      attributes {
                        user {
                          data {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      }
    }
  }
}`;

export const GET_BOOKMARKS = gql`
query getUser($id:ID!){
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        bookmarkSends {
          data {
            id
          }
        }
        partyMembers {
          data {
            id
            attributes {
              party {
                data {
                  id
                  attributes {
                    building
                    meetedAt
                    address
                    addressDetail
                    restaurantName
                    members {
                      data {
                        id
                        attributes {
                          user {
                            data {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;