import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getLoginLink } from "../../toolbox/kakao";
import { useDispatch, useSelector } from "react-redux";
import { Timer } from "./Timer";
import moment from "moment-timezone";
import "moment/locale/ko";
import { useQuery } from "@apollo/client";
import { flatEntity } from "../../toolbox/query";
import { useEffect, useState } from "react";
import { RecommendUsers } from "../components/RecommendUsers";
import { getUpcomingMeet } from "../../toolbox/time";
import RestSwiper from "../components/RestSwiper";
import { GET_RESTAURANTS } from "../../query/partyQuery";
import queryString from "query-string";
import { sessionGuardAction } from "../../redux/sessionGuardReducer";
import { toastAction } from "../../redux/toastReducer";
import { isEmpty } from "lodash";
import { HelpBtn } from "../components/FormComponent";

export const LandingPage2 = ({}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const parsed = location.search
        ? queryString.parse(location.search, { ignoreQueryPrefix: true })
        : {};

    const tokenInfo = useSelector(({ auth }) => auth.tokenInfo);
    const isLoggedIn = !isEmpty(tokenInfo);
    // const [parties, setParties] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    const [restaurantImages, setRestaurantImages] = useState(null);

    // let now = moment.tz('Asia/Seoul').toISOString();

    const { data: restaurantData } = useQuery(GET_RESTAURANTS, {
        fetchPolicy: "cache-first",
    });

    // useEffect(() => {
    //     setStartDate(moment.tz('Asia/Seoul').toISOString());
    // }, []);

    useEffect(() => {
        if (restaurantData?.restaurants?.data) {
            const payload = flatEntity(restaurantData.restaurants.data);
            const result = [];
            for (let r of payload) {
                if (r.thumbnail) {
                    result.push({
                        url: r.thumbnail.url,
                        link: r.placeLink,
                    });
                }
            }
            setRestaurantImages(result);
        }
    }, [restaurantData]);

    useEffect(() => {
        if (parsed.inviteKey) {
            dispatch(
                sessionGuardAction.update({
                    key: "inviteKey",
                    value: parsed.inviteKey,
                })
            );
            const searchParams = new URLSearchParams(location.search);
            searchParams.delete("inviteKey");
            navigate(
                {
                    pathname: location.pathname,
                    search: searchParams.toString(),
                },
                { replace: true }
            );
            dispatch(
                toastAction.toast({
                    severity: "success",
                    message:
                        "지인의 초대링크를 통해 접속했습니다. 지금 회원가입하고 디너모임 무료참석권 3장을 받아보세요!",
                })
            );
        }
    }, [parsed.inviteKey]);

    // const getNextFriday = () => {
    //     let to = moment.tz('Asia/Seoul').day(5).hour(19).minutes(0).seconds(0);
    // }
    //
    // const getNextNextFriday = () => {
    //     return moment.tz('Asia/Seoul').day(12).hour(19).minutes(0).seconds(0);
    // }

    return (
        <PageWrap>
            <header className="header">
                <p>이번 디너모임 신청 마감까지</p>
                <Timer />
                <div className="container">
                    {/*<h1>{`${getNextNextFriday().format('M월 D일')}에 바쁘세요?`}</h1>*/}
                    <h1>
                        목요일 저녁 7시,
                        <br />
                        새로운 사람들과 저녁식사를
                    </h1>
                    {/*<p>지금 가입하시면 목요일에 강남역 인근에서 모임이 진행됩니다. <span onClick={() => alert('모임 인원이 확정되면 알림을 보내드립니다.')} n style={{cursor: 'pointer', textDecoration: 'underline'}}>(?)</span></p>*/}
                    <p>다양한 분야의 사람들과 친구가 되어보세요</p>
                    <div>
                        <Link
                            to={"/my"}
                            className="cta-button"
                        >{`${getUpcomingMeet().format(
                            "M월 D일 (dd) HH:mm 저녁 같이하기"
                        )}`}</Link>
                        <p className="small-desc">
                            ※ 친구와 참석하고 싶다면, 신청 후 우하단 톡상담으로
                            말씀해주세요.
                        </p>
                    </div>
                    {/*{tokenInfo?*/}
                    {/*    <Link to={'/my'} className="cta-button">내 프로필 설정하기</Link>*/}
                    {/*    :<Link to={'/purchase'} className="cta-button">{`${calcUpcomingMeet().format('M월 D일 (A) 저녁 같이하기')}`}</Link>}*/}
                </div>
            </header>

            <section className="benefits">
                <div className="container">
                    <h2>우연한디너가 처음이라면? 무료로 참석해보세요!</h2>
                    <ul>
                        <li>
                            (1) 회원 가입 시 3회 무료참석 가능한 예비우디너
                            <HelpBtn
                                contents={
                                    "우디는 모임 후 참석자 피드백을 주거나 받는 것으로 각자의 취향을 형성하고, 이를 기반으로 다음 디너모임 멤버를 구성하는 방식이야.\n\n그런데 이제 막 회원가입을 한 예비우디너는 피드백 데이터가 없기 때문에, 예비우디너들끼리 랜덤으로 구성되는 모임에서 피드백을 주고받으면서 알고리즘을 쌓아가는 시간이 필요해.\n" +
                                    "\n" +
                                    "혹시 내가 맘에 드는 참석자가 없더라도, 상대방으로부터 좋은 평가를 받으면 다음에 더 좋은 모임에 참여할 수 있게해주니까 매너를 잘 지켜주면 좋아.\n" +
                                    "\n" +
                                    "모임 후 받는 누적 피드백이 일정점수 이상이어야 정식우디너가 될 수 있으며, 반대로 모임 후 경고 피드백이 누적될 경우 더 이상 모임에 참석할 수 없으니 매너에 유의해줘.\n" +
                                    "\n" +
                                    "정식우디너로 정식 디너모임에 참여하려면?\n" +
                                    "예비 디너모임 3회 이상 참석과 모임 후 평가점수, 매너점수 등 종합적인 피드백 요건을 통과해야해."
                                }
                            />
                            로 등록됩니다.{" "}
                            <Link to="/my">
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        color: "blue",
                                    }}
                                >
                                    [참석 신청하러 가기]
                                </span>
                            </Link>
                        </li>
                        <li>
                            (2) 심심해 하는 친구에게 새로운 친구를 만날 기회를
                            선물해주세요.{" "}
                            <Link to="/my?invite=true">
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        color: "blue",
                                    }}
                                >
                                    [친구 초대하기]
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="benefits">
                <div className="container">
                    <h2>요즘, 새로운 사람 어디서 만나?</h2>
                    <ul>
                        <li>
                            늘 보는 친구도 좋지만, 한달에 한번은 새로운 사람들과
                            대화해보는 건 어떨까?
                        </li>
                        <li>
                            우디는 서로 호감을 가질만한 4~6인이 함께 하는
                            저녁식사 모임을 구성해줄거야.
                        </li>
                        <li>
                            참석할수록 점점 호감도가 높은 유형의 사람들끼리
                            구성되는 알고리즘을 경험할 수 있지.
                        </li>
                        <li>
                            새로운 사람들과 대화로 시작해 봐- 대화가 잘 통하면
                            새로운 친구가 생길수도?
                        </li>
                        <li>
                            약속없는 목요일에 신청만 하면 장소, 멤버는 우디가
                            만들어 줄께- 심지어 예비모임은 무료
                        </li>
                        <li>
                            혹시 불편했던 사람은 회피리스트에 올리면 같은 모임에
                            배정되지 않으니 안심해!
                        </li>
                        {/*<li>그리고, 친구가 심심해 할 때 새로운 사람들과의 모임을 선물해주는 것도 추천할게.</li>*/}
                        <li>
                            {" "}
                            <Link to="/about">
                                <span
                                    style={{
                                        textDecoration: "underline",
                                        color: "blue",
                                    }}
                                >
                                    [좀 더 자세한 서비스 소개보기]
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>

            <RecommendUsers
                title={"프로필 둘러보기"}
                style={{ margin: "32px 0" }}
            />

            <section className="reviews">
                <div className="container">
                    <h2>참가자들이 경험한 우디의 매력</h2>
                    <p>
                        “약속 없는 목요일에 우연히 나가봤는데, 기대 이상으로
                        좋은 대화를 나눠서 만족해요!”
                    </p>
                    <p>
                        “한번 만난 사람은 다시 보지 않으니까 다음 모임에
                        부담없이 또 나갈 수 있어서 좋았습니다.”
                    </p>
                    <p>
                        “어차피 저녁을 먹어야 하는데, 혼밥보다 다른 사람들과
                        얘기를 나누는 시간이 유익했어요.”
                    </p>
                    <p>
                        “자주 보는 사람들 외에 다양한 사람들을 만나보고 싶었는데
                        딱 좋았습니다.”
                    </p>
                    <p>
                        “꼭 데이팅을 해야 해? 꼭 취미를 가져야 해? 그냥 편하게
                        대화하고 싶었는데 우디가 딱 그런 곳이네요.”
                    </p>
                </div>
            </section>

            <div className="pictures">
                <h2>우리의 다음 식사 장소는?</h2>
                <div style={{ paddingBottom: "40px" }}>
                    <RestSwiper images={restaurantImages ?? []} />
                </div>
            </div>

            <section className="footer">
                <div className="container">
                    <h1>
                        새로운 사람들과 저녁식사 어때요?
                        <br />
                        지금 신청하고 우연한 만남을 경험해보세요
                    </h1>
                    <div style={{ marginTop: "16px" }}>
                        <Link
                            to={"/my"}
                            className="cta-button"
                        >{`${getUpcomingMeet().format(
                            "M월 D일 (dd) HH:mm 저녁 같이하기"
                        )}`}</Link>
                        <p className="small-desc">
                            ※ 현재 모임은 강남역 인근에서 진행되고 있습니다.
                        </p>
                    </div>
                </div>
            </section>
            {/*<section className="reviews">*/}
            {/*    <div className="container">*/}
            {/*        <h2>예정된 모임</h2>*/}
            {/*        {parties?.map((p) => <Link to={"/party_detail/" + p.id}>*/}
            {/*            <p>{p.address} - {moment.tz(p.meetedAt, 'Asia/Seoul').format('M월 D일 A hh:mm ')}</p>*/}
            {/*        </Link>)}*/}
            {/*    </div>*/}
            {/*</section>*/}
        </PageWrap>
    );
};
const PageWrap = styled.div`
    font-family: "Elice Digital Baeum", sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    color: #333;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: keep-all;

    //.gray {
    //  color: rgb(199, 199, 204);
    //}

    .header {
        position: relative;
        height: calc(100vh - 60px);
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        word-break: keep-all;
        text-align: center;
        padding: 30px 20px 90px;
        gap: 10px;
        color: ${({ theme }) => theme.colors.textColor};
        background: transparent;
        z-index: 1;
        width: 100%;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .header::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/rest2.png"); /* 배경 이미지 경로 */
        background-size: cover; /* 배경 이미지가 요소를 덮도록 크기를 조정 */
        background-position: center; /* 배경 이미지의 위치를 가운데로 설정 */
        background-repeat: no-repeat; /* 배경 이미지가 반복되지 않도록 설정 */
        //filter: brightness(1.2); /* 밝기를 120%로 설정 */
        z-index: -2; /* 배경 이미지가 투명한 판 뒤에 위치하도록 설정 */
    }

    .header::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7); /* 흰색 투명한 판 */
        z-index: -1; /* 투명한 판이 컨텐츠 뒤에 위치하도록 설정 */
    }

    .header .container {
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        //flex-grow: 1;
        flex-direction: column;
        gap: 16px;
        justify-content: space-around;
    }

    .footer {
        margin-top: 80px;
        margin-bottom: 20px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        word-break: keep-all;
        text-align: center;
        padding: 30px 20px 90px;
        gap: 10px;
        color: ${({ theme }) => theme.colors.textColor};
        background: transparent;
        z-index: 1;
        width: 100%;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .logo-icon {
        font-size: 2.5em;
        margin-right: 10px;
        animation: spin 4s linear infinite;
    }

    .logo-text {
        font-size: 2.5em;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .header h1 {
        font-size: 2em;
        font-weight: 600;
        margin: 0 0 10px;
    }

    .header p {
        font-size: 1em;
        font-weight: 600;
    }

    .small-desc {
        margin-top: 2px;
        font-size: 14px;
        font-weight: 400;
    }

    .cta-button {
        margin: 16px 0 4px;
        display: inline-block;
        padding: 15px 30px;
        color: ${({ theme }) => theme.colors.backgroundColor};
        text-decoration: none;
        font-size: 1.2em;
        //transition: background 0.3s ease, transform 0.3s ease;
        //background-color: transparent;
        //background-image: linear-gradient(225deg, #E74011 0%, #EB5D40 100%);
        //border-radius: 30px;
        //box-shadow: 0 3px 35px 0 #EB5D40;

        transition: background 0.3s ease, transform 0.3s ease;
        // border: 1px solid ${({ theme }) => theme.colors.textColor};
        // background-color: ${({ theme }) => theme.colors.lightGray};
        background-color: ${({ theme }) => theme.colors.primary};

        &:hover {
            background-color: ${({ theme }) => theme.colors.primaryDark};
        }
        //background-image: linear-gradient(225deg, ${({ theme }) =>
            theme.colors.backgroundColor} 0%, ${({ theme }) =>
            theme.colors.lightGray} 10%);
        border-radius: 30px;
        //box-shadow: 0 3px 35px 0 rgba(255, 105, 180, 0.5);
    }

    .benefits,
    .reviews,
    .pictures {
        padding: 40px 20px;
        width: 100%;
        background: white;
        border-radius: 10px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
    }

    .benefits .container,
    .reviews .container {
        max-width: 800px;
        margin: 0 auto;
    }

    .pictures {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .benefits h2,
    .reviews h2,
    .pictures h2 {
        font-size: 2em;
        margin-bottom: 20px;
        text-align: center;
        color: #2c3e50;
    }

    .benefits ul {
        list-style: none;
        padding: 0;
    }

    .benefits li {
        //display: flex;
        background: #f3f3f3;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .reviews p {
        background: #f3f3f3;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
`;
