import './App.css';
import {ThemeProvider} from "styled-components";
import theme from "./layout/theme";
import Layout from "./layout/Layout";
import {Route, Routes, useLocation} from "react-router-dom";
import {PartyDetailPage} from "./page/party/PartyDetailPage";
import GlobalStyle from "./layout/GlobalStyle";
import {PartyMemberPage} from "./page/party/PartyMemberPage";
import {ProfileEditPage} from "./page/profile/ProfileEditPage";
import {useDispatch, useSelector} from "react-redux";
import LoadingIndicator from "./layout/LoadingIndicator";
import {LandingPage2} from "./page/landing/LandingPage2";
import {LoginPage} from "./page/login/LoginPage";
import {LoginCallbackPage} from "./page/login/LoginCallbackPage";
import {useEffect, useState} from "react";
import {authAction} from "./redux/authReducer";
import {PaymentSuccessPage} from "./page/payment/PaymentSuccess";
import {PurchasePage} from "./page/my/PurchasePage";
import {PartyReqPage} from "./page/my/PartyReqPage";
import {userAction} from "./redux/userReducer";
import {flatEntity} from "./toolbox/query";
import {GET_ME, UPDATE_USER} from "./query/userQuery";
import {useMutation, useQuery} from "@apollo/client";
import {get, isEmpty} from "lodash";
import {ProfileDetailPage} from "./page/profile/ProfileDetailPage";
import {BookmarkPage} from "./page/my/BookmarkPage";
import {BookmarkMorePage} from "./page/my/BookmarkMorePage";
import {PartyAfterPage} from "./page/party/PartyAfterPage";
import {RestaurantPage} from "./page/restaurant/RestaurantPage";
import {loadingAction} from "./redux/loadingReducer";
import {TestPage} from "./page/my/TestPage";
import {MyProfilePage} from "./page/my/MyProfilePage";
import {PartyAdminPage} from "./page/admin/PartyAdminPage";
import {KakaoFloat} from "./layout/KakaoFloat";
import {Alert, Snackbar} from "@mui/material";
import {toastAction} from "./redux/toastReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "./index";
import {sessionGuardAction} from "./redux/sessionGuardReducer";
import {CardSuccessPage} from "./page/payment/CardSuccess";
import {SubscriptionPage} from "./page/my/SubscriptionPage";
import {CardFailPage} from "./page/payment/CardFail";
import {CouponPage} from "./page/my/CouponPage";
import {MyPage} from "./page/my/MyPage";
import {PartyListPage} from "./page/my/PartyListPage";
import {AboutPage} from "./page/landing/AboutPage";
import {QuestionPage} from "./page/game/QuesteionPage";
import {EvadePage} from "./page/my/EvadePage";
import {PartyModifyPage} from "./page/party/PartyModifyPage";
import {ChatDetailPage} from "./page/my/ChatDetailPage";
import {ChatCreatePage} from "./page/my/ChatCreatePage";
import {ChatDirectPage} from "./page/my/ChatDirectPage";
import { ChatListPage } from './page/my/ChatList';
import {LeavePage} from "./page/my/LeavePage";

function App() {
    const dispatch = useDispatch();
    const { loading } = useSelector(({loading}) => loading);
    const jwt = useSelector(({auth}) => auth.token);
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const storage = useSelector(({sessionGuard}) => sessionGuard.storage);
    const refresh = useSelector(({auth}) => auth.refresh);
    const { toast } = useSelector(({toast}) => toast);
    const location = useLocation();
    const [updateUser] = useMutation(UPDATE_USER);

    const [toastOpen, setToastOpen] = useState(false);

    const {data: user, refetch: refetchUser} = useQuery(GET_ME, {
        fetchPolicy: "cache-first",
        skip: !jwt,
        variables: {id: tokenInfo?.id}
    });

    useEffect(() => {
        if (user) {
            const payload = get(user, "usersPermissionsUser.data", null);
            dispatch(userAction.update(flatEntity(payload)));
        }
    }, [user]);

    useEffect(() => {
        dispatch(loadingAction.loading(false));
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        if (tokenInfo) {
            const period = 86400 * 7;
            const now = Date.now() / 1000;
            if (tokenInfo.exp < now + period) {
                dispatch(authAction.logout());
            }
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (storage.inviteKey) {
            if (isEmpty(tokenInfo)) {
                // dispatch(toastAction.toast({severity: 'success', 'message': '로그인 시...'}));
            } else {
                axios.post(SERVER_ADDRESS + '/api/invites/submit', {
                    inviteKey: storage.inviteKey,
                }).then((res) => {
                    if (res.data) {
                        const payload = {
                            refetchQueries: [{query: GET_ME, variables: { id: tokenInfo.id }}],
                            variables: {
                                id: tokenInfo.id,
                                data: {}
                            }
                        };
                        updateUser(payload).then(() => {
                            dispatch(toastAction.toast({severity: res.data.result, message: res.data.msg}));
                        });
                    }
                });
                dispatch(sessionGuardAction.delete({key: 'inviteKey'}));
            }
        }
    }, [storage, tokenInfo]);

    useEffect(() => {
        if (toast) {
            setToastOpen(true);
        } else {
            setToastOpen(false);
        }
    }, [toast]);

    return (
      <ThemeProvider theme={theme}>
          <GlobalStyle/>
          <Layout>
              <Routes>
                  <Route path="/" element={<LandingPage2 />} />
                  <Route path="/party_detail/:id" element={<PartyDetailPage />} />
                  <Route path="/party_modify/:id" element={<PartyModifyPage />} />
                  <Route path="/party_after/:id" element={<PartyAfterPage />} />
                  <Route path="/member_detail/:id" element={<PartyMemberPage />} />
                  <Route path="/profile_edit" element={<ProfileEditPage />} />
                  <Route path="/user/:id" element={<ProfileDetailPage />} />
                  <Route path="/my_profile" element={<MyProfilePage />} />
                  <Route path="/test" element={<TestPage />} />
                  <Route path="/my" element={<PartyReqPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/my/party" element={<PartyListPage />} />
                  <Route path="/my/info" element={<MyPage />} />
                  <Route path="/my/bookmark" element={<BookmarkPage />} />
                  <Route path="/my/bookmark_more" element={<BookmarkMorePage />} />
                  <Route path="/my/coupon" element={<CouponPage />} />
                  <Route path="/my/evade" element={<EvadePage />} />
                  <Route path="/my/chat" element={<ChatListPage />} />
                  <Route path="/my/chat/:id" element={<ChatDetailPage />} />
                  <Route path="/my/chat_start" element={<ChatCreatePage/>}/>
                  <Route path="/my/chat_direct" element={<ChatDirectPage/>}/>
                  <Route path="/my/leave" element={<LeavePage />} />


                  <Route path="/login" element={<LoginPage/>}/>
                  <Route path="/login_callback" element={<LoginCallbackPage/>}/>

                  <Route path="/card_success" element={<CardSuccessPage/>}/>
                  <Route path="/card_fail" element={<CardFailPage/>}/>
                  <Route path="/payment_success" element={<PaymentSuccessPage/>}/>
                  <Route path="/purchase" element={<PurchasePage/>}/>
                  <Route path="/subscription" element={<SubscriptionPage/>}/>
                  <Route path="/question" element={<QuestionPage/>}/>


                  <Route path="/admin" element={<PartyAdminPage />} />
              </Routes>
              <KakaoFloat />
          </Layout>
          <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} autoHideDuration={3000} open={toastOpen} onClose={() => setToastOpen(() => false)} >
              <Alert severity={toast.severity ?? 'success'}>{toast.message}</Alert>
          </Snackbar>
          {loading && <LoadingIndicator />}
      </ThemeProvider>
  );
}

export default App;
