import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {filter, find, isEmpty, shuffle} from "lodash";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import 'moment/locale/ko';
import queryString from "query-string";
import {getLoginLink} from "../../toolbox/kakao";
import {FullScreen} from "../../layout/FullScreen";
import {UserMessage} from "./ChatDetailPage";
import {useQuery} from "@apollo/client";
import {GET_QUESTION} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {PositiveButton} from "./PartyReqPage";
import { useMemo } from "react";
import { useCallback } from "react";

export const ChatDirectPage = () => {
    const location = useLocation();

    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;
    const userId = parsed.userId;

    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const [send, setSend] = useState(false);
    const [profile, setProfile] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    useEffect(() => {
        if (userId) {
            axios.post(SERVER_ADDRESS + '/api/party-members/getProfile', {userId: userId}).then((res) => {
                if (res.data) {
                    setProfile(res.data);
                }
            });
        }
    }, [userId]);

    const chatRoomLink = useMemo(() => {
        return "/user/" + userId;
    }, [userId]);

    const chatRoomTitle = useMemo(() => {
        if (!profile) {
            return '';
        }
        return <a href={chatRoomLink}>
            <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                <ProfileImage src={profile?.profileImage?.url ?? "/defaultProfile2.png"} />
                <span>{profile?.nickname}</span>
            </div>
        </a>
    }, [profile, chatRoomLink]);

    useEffect(() => {
        if (userId) {
            axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
                if (res.data) {
                    const room = find(res.data, r => !r.title && !r.party
                        && find(r.users, u => String(u.id) === String(userId))
                        && r.users.length === 2);
                    if (room) {
                        navigate('/my/chat/' + room.id);
                    }
                }
            });
        }
    }, [userId, navigate]);

    const handleSend = useCallback((message) => {
        if (send) {
            return;
        }
        setSend(true);
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/createDirectRoom', {
            message: {
                type: 'text',
                content: message,
            },
            targetUserId: userId,
        }).then((res) => {
            navigate('/my/chat/' + res.data.id);
        });
    }, [send, navigate]);

    return <FullScreen>
        <div style={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
            <div style={{fontSize: '20px', padding: '16px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
                {chatRoomTitle}
            </div>
            <Wrap>
            </Wrap>
            <ChatInput onSend={handleSend} />
        </div>
    </FullScreen>
}

const Wrap = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
  height: 100%;
  //height: calc(100vh - 300px);
  overflow-y: auto;
`

const ChatInputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const TextInput = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;
  padding-left: 12px;
  height: 45px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #888;
  }
  
  .image {
    padding: 10px 10px 6px;
    height: 100%;
    flex-grow: 1;
    cursor: pointer;
  }

  textarea {
    font-size: 14px;
    padding: 10px 10px 10px 0;
    width: 100%;
    resize: none;
    overflow-y: auto;
    border: none;
    //min-height: 10px;
    //max-height: 26px;
    max-height: 41px;
    flex-grow: 1;
    &:focus {
      outline: none;
      border-color: #888;
    }
  }
`;

const SendButton = styled.button`
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChatInput = ({ onSend }) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [isComposition, setIsComposition] = useState(false);

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleInput = () => {
        textareaRef.current.style.height = 'auto'; // 초기화
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 18}px`;
    };

    const handleSend = () => {
        if (message.trim() && !isComposition) {
            onSend(message.trim());
            setMessage('');
        }
    };

    return (
        <ChatInputContainer>
            <TextInput>
                <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={handleChange}
                    onInput={handleInput}
                    onCompositionStart={() => setIsComposition(true)}
                    onCompositionEnd={() => setIsComposition(false)}
                    rows={1}
                    onKeyDown={handleKeyDown}
                    placeholder="메시지를 입력하세요..." />
            </TextInput>
            <SendButton onClick={handleSend}>전송</SendButton>
        </ChatInputContainer>
    );
};


const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
