const ADMINS = [
    "199", // 재창
    "34", // 성환
    "36", // 윤석
    "2274", // 윤석
];

export const isAdmin = (userId) => {
    return ADMINS.includes(String(userId));
};

export const openAddress = (callback) => {
    console.log("GO");
    const postCode = new window.daum.Postcode({
        oncomplete: (response) => {
            let address = response.autoJibunAddress || response.jibunAddress;
            callback(address);
            // if (response.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
            //     address = response.autoRoadAddress || response.roadAddress;
            // }

            // let geocoder = new window.kakao.maps.services.Geocoder();
            // geocoder.addressSearch(address, (result, status) => {
            //     if (status === window.kakao.maps.services.Status.OK) {
            //         // let simpleAddress = result[0].address;
            //         // const depth2 = simpleAddress.address_name.indexOf(simpleAddress.region_2depth_name)
            //         // simpleAddress = result[0].address_name.slice(depth2);
            //         const longitude = Number(result[0].x);
            //         const latitude = Number(result[0].y);
            //         callback(address, longitude, latitude);
            //     } else {
            //         callback(address, null, null);
            //     }
            // });
        },
    });
    postCode.open();
};
