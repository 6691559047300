import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {filter, find, isEmpty, shuffle} from "lodash";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import 'moment/locale/ko';
import queryString from "query-string";
import {getLoginLink} from "../../toolbox/kakao";
import {FullScreen} from "../../layout/FullScreen";
import {UserMessage} from "./ChatDetailPage";
import {useQuery} from "@apollo/client";
import {GET_QUESTION} from "../../query/partyQuery";
import {flatEntity} from "../../toolbox/query";
import {PositiveButton} from "./PartyReqPage";

export const ChatCreatePage = () => {
    const location = useLocation();

    const parsed = location.search ? queryString.parse(location.search, {ignoreQueryPrefix: true}) : null;

    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const [question, setQuestion] = useState('최근에 기분이 제일 좋았던 순간은?');
    const [questionList, setQuestionList] = useState(['최근에 기분이 제일 좋았던 순간은?']);
    const [questionCount, setQuestionCount] = useState(0);
    const [send, setSend] = useState(false);

    const navigate = useNavigate();
    const {data, refetch} = useQuery(GET_QUESTION, {});

    useEffect(() => {
        if (data?.article?.data) {
            const payload = flatEntity(data.article.data);
            let questions = payload.text.split('\n');
            questions = filter(questions, q => q.trim());
            setQuestionList(shuffle(questions.map(q => q.trim())));
        }
    }, [data]);

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const getChatRoomTitle = () => {
        return <>
            <span>우연한 채팅방</span>
        </>
    }

    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
            if (res.data) {
                if (res.data.length) {
                    const room = find(res.data, r => !r.title && !r.party && r.users.length === 1);
                    if (room) {
                        navigate('/my/chat/' + room.id);
                    }
                }
            }
        });
    }, []);

    const handleSend = (message) => {
        if (send) {
            return;
        }
        setSend(true);
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/createRoom', {
            message: {
                type: 'text',
                content: message,
            },
            question: `Q. ${question}`,
        }).then((res) => {
            navigate('/my/chat/' + res.data.id);
        });
    }

    const onQuestion = () => {
        let newCount = questionCount + 1;
        if (questionList.length <= newCount) {
            newCount = 0;
        }
        setQuestion(questionList[newCount]);
        setQuestionCount(newCount);
    }

    return <FullScreen>
        <div style={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
            <div style={{fontSize: '20px', padding: '16px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
                {getChatRoomTitle()}
            </div>
            <Wrap>
                <UserMessage isOwnMessage={false} prev={false} senderId={'BOT'} type={'text'} text={'안녕하세요! 저는 우디봇이에요 🤖\n여러분이 서로 더 쉽게 알아갈 수 있도록 도와드릴 거예요!'} hideNickname={false} isRead={""} nickname={"우디봇"} time={""} profileImage={"/botProfile.png"} />
                <UserMessage isOwnMessage={false} prev={true} senderId={'BOT'} type={'text'} text={'간단한 질문에 답해주시면, 방금 가입한 새로운 사람과 마니또로 연결되어 같은 방에서 대화를 해볼 수 있습니다.'} hideNickname={true} isRead={""} nickname={"우디봇"} time={""} profileImage={"/botProfile.png"} />
                <UserMessage isOwnMessage={false} prev={true} senderId={'BOT'} type={'text'} text={'모임 전 예비단계로 편안하게 생각하시고 솔직하게 답변해 주세요! 😊'} hideNickname={true} isRead={""} nickname={"우디봇"} time={""} profileImage={"/botProfile.png"} />
                <UserMessage isOwnMessage={false} prev={true} senderId={'BOT'} type={'text'} text={`Q. ${question}`} hideNickname={false} isRead={""} nickname={"우디봇"} time={""} profileImage={"/botProfile.png"} />
            </Wrap>
            <PositiveButton style={{margin: '2px 4px'}} onClick={onQuestion}>새 질문 뽑기</PositiveButton>
            <ChatInput onSend={handleSend} />
        </div>
    </FullScreen>
}

const Wrap = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
  height: 100%;
  //height: calc(100vh - 300px);
  overflow-y: auto;
`

const ChatInputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const TextInput = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  align-items: center;
  padding-left: 12px;
  height: 45px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #888;
  }
  
  .image {
    padding: 10px 10px 6px;
    height: 100%;
    flex-grow: 1;
    cursor: pointer;
  }

  textarea {
    font-size: 14px;
    padding: 10px 10px 10px 0;
    width: 100%;
    resize: none;
    overflow-y: auto;
    border: none;
    //min-height: 10px;
    //max-height: 26px;
    max-height: 41px;
    flex-grow: 1;
    &:focus {
      outline: none;
      border-color: #888;
    }
  }
`;

const SendButton = styled.button`
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChatInput = ({ onSend }) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [isComposition, setIsComposition] = useState(false);

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        if (!isMobile && e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleInput = () => {
        textareaRef.current.style.height = 'auto'; // 초기화
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 18}px`;
    };

    const handleSend = () => {
        if (message.trim() && !isComposition) {
            onSend(message.trim());
            setMessage('');
        }
    };

    return (
        <ChatInputContainer>
            <TextInput>
                <textarea
                    ref={textareaRef}
                    value={message}
                    onChange={handleChange}
                    onInput={handleInput}
                    onCompositionStart={() => setIsComposition(true)}
                    onCompositionEnd={() => setIsComposition(false)}
                    rows={1}
                    onKeyDown={handleKeyDown}
                    placeholder="메시지를 입력하세요..." />
            </TextInput>
            <SendButton onClick={handleSend}>전송</SendButton>
        </ChatInputContainer>
    );
};


const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
