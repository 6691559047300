import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {cloneDeep, filter, find, get, groupBy, isEmpty, orderBy, reverse, sortBy} from "lodash";
import {useMutation, useQuery} from "@apollo/client";
import {loadingAction} from "../../redux/loadingReducer";
import axios from "axios";
import {SERVER_ADDRESS} from "../../index";
import ReactMarkdown from "react-markdown";
import {authAction} from "../../redux/authReducer";
import moment from "moment-timezone";
import queryString from "query-string";
import {getLoginLink} from "../../toolbox/kakao";

export const _ChatListPage = ({className}) => {
    const [chatRooms, setChatRooms] = useState(null);
    useEffect(() => {
        axios.post(SERVER_ADDRESS + '/api/chat-rooms/findMyRooms', {}).then((res) => {
            setChatRooms(res.data);
        });
    }, []);
    return <div className={className}>
        <ChatList chatRooms={chatRooms} />
    </div>
}

export const ChatListPage = styled(_ChatListPage)`
    padding: 16px;
    max-width: 579px;
    margin: 0 auto;
`;

export const ChatList = ({chatRooms, max}) => {
    const dispatch = useDispatch();
    const tokenInfo = useSelector(({auth}) => auth.tokenInfo);
    const userInfo = useSelector(({user}) => user.user);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isEmpty(tokenInfo)) {
            const to = getLoginLink(location);
            navigate(to);
        }
    }, [tokenInfo]);

    const getChatRoomTitle = (room) => {
        if (room.title) {
            return <span>{room.title}</span>;
        }
        const otherUsers = filter(room.users, u => String(u.id) !== String(tokenInfo.id));
        if (otherUsers.length) {
            return <>
                <span>{otherUsers.map(u => u.nickname).join(', ')}</span>
            </>;
        }
        return "우연한 채팅방";
    }

    const getChatRoomImage = (room) => {
        return "/defaultProfile2.png";
    }

    const getLastMessage = (room) => {
        if (!room.lastMessage) {
            return '표시할 메세지가 없습니다.';
        }
        if (room.lastMessage.type === 'text') {
            return room.lastMessage.content;
        }
        if (room.lastMessage.type === 'image') {
            return "사진을 보냈습니다.";
        }
    }

    const getUnread = (room) => {
        const lastMessage = room?.lastMessage?.sentAt;
        if (lastMessage) {
            const read = room.readAt?.[tokenInfo.id];
            if (read && lastMessage <= read) {
                return false;
            }
            return true;
        }
        return false;
    }

    const getChatRooms = () => {
        let cr = orderBy(chatRooms, ['lastMessage.sentAt'], ['desc']);
        if (max) {
            return cr.splice(0, max);
        }
        return cr;
    }

    return <div>
        {chatRooms && getChatRooms().map((room) =>
            <Link to={"/my/chat/" + room.id}>
                <ChatRoom>
                    <div className="container">
                        <img className="profile_image" src={getChatRoomImage(room)} />
                        <div className="content">
                            <div className="title">{getChatRoomTitle(room)}</div>
                            <div className={getUnread(room)? 'unread-message': 'read-message'}>{getLastMessage(room)}</div>
                        </div>
                        {getUnread(room) && <div className="unread" />}
                    </div>
                </ChatRoom>
            </Link>)}

        {chatRooms && chatRooms.length === 0 &&
            <Link to="/my/chat_start">
                <ChatRoom>
                    <div className="button">우연한 채팅방 시작하기</div>
                </ChatRoom>
            </Link>}
    </div>
}

const ChatRoom = styled.div`
  padding: 8px 10px;
  box-sizing: border-box;
  
  .button {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #EEEEEE;
    }
  }
  .container {
    box-sizing: border-box;
    width: 100%;
    min-width: 250px;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #EEEEEE;
    }
  }


  .profile_image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .content {
    box-sizing: border-box;
    width: 100%;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }
  
  .title {
    box-sizing: border-box;
    font-weight: 600;
  }

  .unread-message {
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .read-message {
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #808080;
  }
  
  .unread {
    margin-left: 10px;
    min-width: 10px;
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 50%;
    display: inline-block;
  }
`;